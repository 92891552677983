import styles from './styles.module.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { curatedTitle } from 'src/utils/curated-title';

import { DefaultLayoutTemplate } from 'src/pages/layout';
import { SectionContainer } from 'src/components/sectionContainer';

export default () => (
	<DefaultLayoutTemplate>
		<Helmet>
			<title>{curatedTitle('Page Not Found')}</title>
		</Helmet>
		<SectionContainer withBorderTop>
			<p className={styles.message}>Page Not Found</p>
		</SectionContainer>
	</DefaultLayoutTemplate>
);
